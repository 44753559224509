import React from "react";
import Button from "@material-ui/core/Button";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import axios from "axios";

export default class ContactUs extends React.Component {
  state = {
    formData: {
      name: "",
      email: "",
      number: "",
      message: "",
    },
    submitted: false,
  };

  handleChange = event => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  };

  handleSubmit = event => {
    event.preventDefault();

    this.setState({ submitted: true }, () => {
      const data = this.state.formData;
      axios
        .post(
          "/.netlify/functions/mail",
          {
            email: data.email,
            name: data.name,
            phone: data.number,
            message: data.message,
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    });
  };

  render() {
    const { formData, submitted } = this.state;

    const Style = {
      background: "white",
      fontSize: "1.5rem",
    };

    return (
      <ValidatorForm
        ref="form"
        onSubmit={this.handleSubmit}
        className="contact-form"
        // data-netlify={"true"}
        name="Contact"
      >
        <div className="two-fields name-email fields">
          <TextValidator
            label="Name"
            onChange={this.handleChange}
            name="name"
            variant="outlined"
            value={formData.name}
            validators={["required"]}
            style={Style}
            errorMessages={["this field is required"]}
          />
          <TextValidator
            label="Email"
            onChange={this.handleChange}
            name="email"
            variant="outlined"
            value={formData.email}
            validators={["required", "isEmail"]}
            style={Style}
            errorMessages={["this field is required", "email is not valid"]}
          />
        </div>
        <div className="one-field phone fields">
          <TextValidator
            label="Phone Number"
            onChange={this.handleChange}
            name="number"
            variant="outlined"
            value={formData.number}
            validators={["required"]}
            style={Style}
            errorMessages={["this field is required", "phone is not valid"]}
          />
        </div>
        <div className="one-field message fields">
          <TextValidator
            label="Message"
            onChange={this.handleChange}
            multiline
            name="message"
            variant="outlined"
            value={formData.message}
            className="message-field"
            validators={["required", "required"]}
            style={Style}
            errorMessages={["this field is required"]}
          />
        </div>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          className="white-text"
          disabled={submitted}
        >
          {(submitted && "Your message sent!") || (!submitted && "Send")}
        </Button>
        <div className="submit fields"></div>
      </ValidatorForm>
    );
  }
}
