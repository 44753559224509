import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/theme/layout";
import SEO from "../components/theme/seo";

import Masthead from "../components/strapi/structure/Masthead";
import Twocol from "../components/strapi/structure/TwoCol";
import OneCol from "../components/strapi/contact/OneCol";
import Outrolocation from "../components/strapi/structure/OutroLocation";
import TestimonialSlider from "../components/testimonials/TestimonialSlider";

export default function Contact({ data }) {
  return (
    <Layout>
      <SEO title="Contact" />
      {data.allStrapiContact.edges.map(({ node }) => (
        <React.Fragment key={node}>
          <Masthead
            mhtitle={node.contact_mh.mh_title}
            mhsubtitle={node.contact_mh.mh_subtitle}
            mhbackground={node.contact_mh.mh_bg.publicURL}
          />
          <div className="contact-intro">
            <Twocol
              title={node.contact_twocol.txtimg_title}
              description={node.contact_twocol.txtimg_description}
              blueTitle={node.contact_twocol.txtimg_bluetitle}
              blueLink={node.contact_twocol.txtimg_bluelink}
              bgColor={node.contact_twocol.txtimg_bgcolor}
              order={node.contact_twocol.txtimg_order}
              rowimg={node.contact_twocol.txtimg_img.publicURL}
            />
          </div>
          <div className="Grey contact-form">
            <OneCol
              title={node.contact_form.onecol_title}
              description={node.contact_form.onecol_description}
            />
          </div>
          <Outrolocation outro={node.contact_outro.location_description} />
          <TestimonialSlider />
        </React.Fragment>
      ))}
    </Layout>
  );
}

export const query = graphql`
  query Contact {
    allStrapiContact {
      edges {
        node {
          id # id of the node
          contact_mh {
            id
            mh_title
            mh_subtitle
            mh_bg {
              publicURL
            }
          }
          contact_twocol {
            id
            txtimg_title
            txtimg_description
            txtimg_order
            txtimg_img {
              publicURL
            }
          }
          contact_form {
            id
            onecol_title
            onecol_description
          }
          contact_outro {
            id
            location_description
          }
        }
      }
    }
  }
`;
