// For use, select the 'One_col' element in Strapi
import React from "react";
import { Link } from "gatsby";
import { Container, Grid, Button } from "@material-ui/core";
import Mailchimp from "./Mailchimp";

export default function Onecol(props) {
  return (
    <div className="Grey py-16">
      <Container className="onecol">
        <Grid item className="md:text-center">
          <h2>{props.title}</h2>
          <div className="w-full md:w-3/5 md:text-center mx-auto">
            <p>{props.description}</p>
            {props.blueLink ? (
              <Link to={props.blueLink}>
                <Button variant="contained" color="primary">
                  <span className="text-white">{props.blueTitle}</span>
                </Button>
              </Link>
            ) : null}
            <Mailchimp />
          </div>
        </Grid>
      </Container>
    </div>
  );
}
